// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-galeria-js": () => import("../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inscripciones-js": () => import("../src/pages/inscripciones.js" /* webpackChunkName: "component---src-pages-inscripciones-js" */),
  "component---src-pages-la-importancia-de-la-emocion-js": () => import("../src/pages/la-importancia-de-la-emocion.js" /* webpackChunkName: "component---src-pages-la-importancia-de-la-emocion-js" */),
  "component---src-pages-nosotros-js": () => import("../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-propuestaeducativa-js": () => import("../src/pages/propuestaeducativa.js" /* webpackChunkName: "component---src-pages-propuestaeducativa-js" */),
  "component---src-pages-reconocimientos-js": () => import("../src/pages/reconocimientos.js" /* webpackChunkName: "component---src-pages-reconocimientos-js" */),
  "component---src-pages-sanitizacion-y-nebulizacion-en-frio-js": () => import("../src/pages/sanitizacion-y-nebulizacion-en-frio.js" /* webpackChunkName: "component---src-pages-sanitizacion-y-nebulizacion-en-frio-js" */)
}

